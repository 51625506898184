import { FunctionComponent } from 'react';
import { DrawPhase } from './DrawPhase';
import { PlayPhase } from './PlayPhase';
import { ScorePhase } from './ScorePhase';
import { getGameState } from '../../redux/slices/game.slice';
import { useAppSelector } from '../../redux/hooks';
import { AfterRandom } from './AfterRandom';

interface TurnProps {
  viewBoard: boolean
}

export const Turn: FunctionComponent<TurnProps> = ({ viewBoard = false }) => {
  const { turnPhase, randomCardPlayed } = useAppSelector(getGameState);

  if (viewBoard) return <DrawPhase />;

  switch (turnPhase) {
    case 'draw':
      if (randomCardPlayed) return <AfterRandom />;
      return <DrawPhase />;
    case 'play':
      return <PlayPhase />;
    case 'score':
      return <ScorePhase />;
    default:
      return <></>;
    //exhaustiveCheck(turnPhase as never);
  }
};
