import React, { FC } from 'react';
import { colors } from '../../theme/theme';
import { Button } from '@primer/react';
import { CSSObject } from 'styled-components';

type Variant =
  | 'juicyPrimary'
  | 'juicySecondary'
  | 'juicyTertiary'
  | 'sparkPrimary'
  | 'sparkSecondary'
  | 'sparkTertiary'
  | 'sparkDisabled'
  | 'realPrimary'
  | 'realSecondary'
  | 'realTertiary'
  | 'realDisabled';
type ButtonProps = {
  readonly variant?: Variant;
  [key: string]: any;
};

const styles: Record<Variant, CSSObject> = {
  juicyPrimary: {
    backgroundColor: colors.accent.emphasis,
    borderColor: colors.accent.border,
    color: '#fff',
    borderLeftWidth: '4px',
    borderBottomWidth: '5px',
    '&:hover': { // Add hover state
      backgroundColor: '#ffaa65',
      borderColor: '#A53200',
    },
  },
  juicySecondary: {
    backgroundColor: colors.juicy.muted,
    borderColor: colors.accent.emphasis,
    color: colors.accent.emphasis,
    borderLeftWidth: '4px',
    borderBottomWidth: '5px',
    '&:hover': { // Add hover state
      backgroundColor: '#ffdec4',
    },
  },
  juicyTertiary: {
    backgroundColor: '#fff',
    borderColor: colors.accent.emphasis,
    color: colors.accent.emphasis,
    '&:hover': { // Add hover state
      backgroundColor: '#ffe8d5',
    },
  },
  sparkPrimary: {
    backgroundColor: colors.spark.btn,
    borderColor: colors.spark.dark,
    color: '#fff',
    borderLeftWidth: '4px',
    borderBottomWidth: '5px',
    '&:hover': { // Add hover state
      backgroundColor: '#BFAFFF',
      borderColor: '#896DFF',
    },
  },
  sparkSecondary: {
    backgroundColor: colors.spark.muted,
    borderColor: colors.spark.dark,
    color: colors.spark.dark,
    borderLeftWidth: '4px',
    borderBottomWidth: '5px',
    '&:hover': { // Add hover state
      borderColor: '#896DFF',
      color: '#896DFF'
    },
  },
  sparkTertiary: {
    backgroundColor: '#fff',
    borderColor: colors.spark.btn,
    color: colors.spark.btn,
    '&:hover': { // Add hover state
      backgroundColor: '#EDE9FF',
      borderColor: '#896DFF',
      color: '#896DFF'
    },
  },
  sparkDisabled: {
    backgroundColor: colors.spark.muted,
    borderColor: colors.spark.new,
    color: colors.spark.new,
  },
  realPrimary: {
    backgroundColor: '#db004f',
    borderColor: '#7a0032',
    color: '#fff',
    borderLeftWidth: '4px',
    borderBottomWidth: '5px',
    '&:hover': { // Add hover state
      backgroundColor: colors.real.muted,
      borderColor: '#db004f',
      color: '#db004f',
    },
  },
  realSecondary: {
    backgroundColor: colors.real.muted,
    borderColor: colors.real.btn,
    color: colors.real.btn,
    borderLeftWidth: '4px',
    borderBottomWidth: '5px',
    '&:hover': { // Add hover state
      borderColor: '#7a0032',
      color: '#7a0032'
    },
  },
  realTertiary: {
    backgroundColor: '#fff',
    borderColor: colors.real.btn,
    color: colors.real.btn,
    '&:hover': { // Add hover state
      backgroundColor: '#ffeae4',
    },
  },
  realDisabled: {
    backgroundColor: '#fff',
    borderColor: 'lightgray',
    color: 'lightgray',
  },
};

export const CJRButton: FC<ButtonProps> = ({ variant = 'juicyPrimary', ...props }) => {
  return (
    <Button size="large" sx={{ width: '100%', ...styles[variant] }} type="button" {...props}>
      {props.children}
    </Button>
  );
};
